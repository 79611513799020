import React from 'react'
import Header from './app/components/header/Header'
import { Main } from './app/components/main/Main'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Discount from './app/components/discount/Discount'
import NotFound from './app/components/notFound/NotFound'

const App = () => {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/discount' element={<Discount />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App