import React from "react";

import styles from "./Main.module.scss";

export const Main = () => {
    return (
        <div className={styles.Main}>
            <div className={styles.fixed_nav}>
                <h1>
                    <a href="#food">Food Menu</a>
                    <a href="#bar">Bar Menu</a>
                    <a href="#hookah">Hookah Menu</a>
                </h1>
            </div>
            <div className={styles.body}>
                <div className={styles.left_menu_column}>
                    <div className={styles.left_menu_column_main}>
                        <div className={styles.img_block}>
                            <img
                                id="food"
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-1.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-2.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-3.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-4.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-5.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                id=""
                                className={styles.img}
                                src="images/food-menu/YVN-lounge-menu-food-6.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.right_menu_column}>
                    <div className={styles.right_menu_column_main}>
                        <div className={styles.img_block}>
                            <img
                                id="bar"
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-1.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-2.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-3.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-4.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-5.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-6.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-7.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-8.png"
                                alt=""
                            />
                        </div>
                        <div className={styles.img_block}>
                            <img
                                className={styles.img}
                                src="images/bar-menu/YVN-lounge-menu-bar-9.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.hookah_menu_column}>
                    <div className={styles.hookah_menu_column_main}>
                        <div className={styles.img_block}>
                            <img
                                id="hookah"
                                className={styles.img}
                                src="images/hookah-menu/Hookah-menu.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
