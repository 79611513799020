import React from 'react'


import styles from './Header.module.scss'

const Header = () => {
  return (
    <div className={styles.Header}>
        <h2>YVN Hookah & Lounge Bar</h2>
    </div>
  )
}

export default Header