import React from 'react'
import { decode } from 'base-64';


import styles from './Discount.module.scss'
import { useSearchParams } from 'react-router-dom';
import NotFound from '../notFound/NotFound';

const Discount = (e) => {

    const [searchParams] = useSearchParams();
    try {
        const fixKey = decode("eXZuMjAyMwo=");
        const encodedString = searchParams.get('key');

        const decodedString = decode(encodedString)

        if (decodedString === fixKey) {
            return (<div className={styles.Discount}>
                <img alt='' src='https://i.ibb.co/Sm1fg94/Screenshot-from-2023-06-05-21-50-57.png'></img>
            </div>
            )
        }
        else {
            throw new Error("No Match")
        }

    } catch (error) {
        return (<NotFound />)
    }

}

export default Discount